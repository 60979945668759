<template>
  <!-- begin::kb-main -->
  <main class="kb-main" id="kb-myclass-ref">
    <lxp-main-header :show-back="true">
      <template v-slot:inner v-if="notice.fileAtchYn === 'Y' && files && files.length > 0">
        <div class="header-column">
          <div class="kb-form-dropdown download-dropdown" :class="{'is-active': showAttachments}" data-offset="bottom-right" @click="showAttachments = !showAttachments">
            <button class="kb-form-dropdown-btn kb-btn kb-btn-secondary kb-btn-sm rounded-lg"><i class="icon-download32"></i><span class="text">첨부파일</span></button>
            <div class="kb-form-dropdown-options">
              <div class="dropdown-option">
                <ul class="dropdown-option-list" v-for="(file,idx) in files" :key="idx">
                  <li class="dropdown-option-item">
                    <a href="javascript:" class="dropdown-option-link" :title="file.fileNm" @click.stop="downloadAtch(file)">
                      <span class="dropdown-option-text">{{ (file.fileNm != null ? file.fileNm : '-') }}</span>
                      <span class="download-mb">{{ (file.fileSz > 0 ? convertToStorageBytes(file.fileSz) : 0) }}</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </template>
    </lxp-main-header>
    <!-- main-content -->
    <div class="main-content main-component">
      <div class="board-view-container">
        <div class="view-header">
          <h3 class="title">{{ notice.title }}</h3>
          <p class="subtitle">{{ timestampToDateFormat(notice.regDt, 'yyyy.MM.dd hh:mm') }} 등록</p>
        </div>
        <div class="view-body min-component">
          <div class="view-content">
            <div class="kb-table-template">
              <div class="kb-table kb-table-bordered editor-area ck-editor__editable" v-html="notice.cn"></div>
            </div>
          </div>
          <div v-if="files.length > 0" class="segment-box my-md-5">
            <strong class="text-lg">첨부파일</strong>
            <ul class="mt-lg-2">
              <li v-for="(file, idx) in files" :key="idx">
                <a @click.stop="downloadAtch(file)" class="kb-mouse-cursor">
                  <span class="text link-dark">{{file.fileNm}}</span>
                  <span class="text text-black-50 ps-md-3">{{ (file.fileSz > 0 ? convertToStorageBytes(file.fileSz) : 0) }}</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- //main-content -->
  </main>
  <!-- end::kb-main -->
</template>

<script>
import {useRoute, useRouter} from 'vue-router';
import {useStore} from 'vuex';
import {
  commonDownloadFile,
  convertToStorageBytes,
  getItem,
  getText,
  lengthCheck,
  timestampToDateFormat
} from '@/assets/js/util';
import {computed, ref} from 'vue';
import {ACT_GET_BOARD_LIST} from '@/store/modules/board/board';
import LxpMainHeader from '@/components/common/LxpMainHeader';
import ApiService from '@/assets/js/api.service';
export default {
  name: 'LearnBulletinView',
  components: {LxpMainHeader},
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const showAttachments = ref(false);

    const lrnPostSn = computed(() => route.params.lrnPostSn);

    const notice = ref({});
    const files = ref([]);

    const getNotice = () => {
      store.dispatch(`board/${ACT_GET_BOARD_LIST}`, {
        lrnPostSn: lrnPostSn.value
      }).then(res => {
        if (lengthCheck(res)) {
          notice.value = getItem(res);
          files.value = notice.value.boardFiles.filter(file => file.lrnPostFileAtchSn > 0);

          readBoard();
        }
      });
    };
    const readBoard = () => {
      ApiService.put(`/v1/app/learns/boards/${lrnPostSn.value}/inq`).then(() => {}).catch(() => {});
    }
    const downloadAtch = (atch) => {
      commonDownloadFile(atch);
    }

    getNotice();

    return {
      route,
      router,
      store,
      showAttachments,
      notice,
      files,
      timestampToDateFormat,
      convertToStorageBytes,
      getText,
      downloadAtch
    }
  }
};
</script>